<template>
  <div class="headerbox">
  <div class="header">
    <div class="headerL">
      <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
    </div>
    <div class="headerC">
      <p>交易详情</p>
    </div>
    <div class="headerR"></div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="kbox"></div>
<div class="paysuccess">
  <div class="pay1">
    <div class="pay1_L">
      <img src="images/pay1.png" />
    </div>
    <div class="pay1_R">
      <p class="p1">付款成功啦！</p>
      <p class="p2">本商城不会以付款异常为由要求您退款，谨防诈骗</p>
    </div> 
  </div>
  <div class="pay2">
    <router-link :to="'/order'" class="seedd">订单列表</router-link>
    <router-link :to="'/orderDetail/'+options.orderId" class="seedd">查看订单</router-link>
    <router-link to="/auctionList" class="comeshop">继续拍卖</router-link>
  </div>
</div>
<div class="kbox"></div>
<div class="w100">
  <img src="images/pay2.png" />
</div>
<div class="clear"></div>
<div class="kbox"></div>
</template>

<script>
import { reactive } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const options = reactive({
      orderId: route.params.id,
    });
   
    return {
      options,
    };
  },
};
</script>

<style>

</style>