<template>
  <div class="headerbox">
  <div class="header">
    <div class="headerL">
      <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
    </div>
    <div class="headerC">
      <p>充值</p>
    </div>
    <div class="headerR"></div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="kbox"></div>
<div class="paybox">
  <van-field v-model="options.amount" label="金额" placeholder="请输入充值金额" />
  <div class="pay3">
    <div class="pay3_L">
      <img src="images/pay3.png" /><span>余额</span>
    </div>
    <div class="pay3_C">可用金额0.00元</div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="paybox">
  <div class="pay3">
    <div class="pay3_L">
      <img src="images/pay4.png" /><span>快捷支付</span>
    </div>
    <div class="pay3_R">
      <div class="gwccheck"></div>
    </div>
  </div>
  <div class="pay3 wx">
    <div class="pay3_L">
      <img src="images/pay6.png" /><span>微信支付</span>
    </div>
    <div class="pay3_R">
      <div class="gwccheck on"></div>
    </div>
    <div class="tuijian">
      <img src="images/jian.png" />
    </div>
  </div>
</div>
<div class="fbox2"></div>
<div class="hejiBox jiesuan">
  <div class="heji">
    <div class="heji_3"><p>充值金额：<span>￥500.00</span></p></div>
    <div class="heji_5">
      <a href="paySuccess.html">充值</a>
    </div>
  </div>
</div>
</template>

<script>
import { reactive } from '@vue/reactivity'
export default {
    setup(){
        const options=reactive({
            amount:"500.00"
        });
        return {
            options
        }
    }
}
</script>

<style>

</style>